import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ component: Component, isAuthenticated }) => {
  return isAuthenticated && localStorage.getItem('step') === '4' ? (
    <Component />
  ) : (localStorage.getItem('step') ?
    <Navigate to="/login" /> :
    <Navigate to="/createaccount" />
  );
};

export default PrivateRoute;
