import { useContext, useMemo, useState } from "react";
import { AuthContext } from "../context/AuthContext";

export const useAuthContext = () => useContext(AuthContext);

export const AuthContextProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    return sessionStorage.getItem('login') === 'true' ? true : false
  })

  const contextValue = useMemo(() => {
    return {
      isAuthenticated,
      setIsAuthenticated
    }
  }, [isAuthenticated])

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
}


