import React, { Suspense, useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import SuspenseLoader from './utils/SuspenseLoader';
import AuthRoute from './components/AuthRoute/AuthRoute';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import CreateRoute from './components/PrivateRoute/CreateRoute';
import { AuthContext } from './context/AuthContext';
import './App.css';
const Home = React.lazy(() => import('./pages/Home'))
const Home2 = React.lazy(() => import('./pages/Home/home'))
const Products = React.lazy(() => import('./pages/Products/Products'))
const Services = React.lazy(() => import('./pages/Products/Services'))
const Wallet = React.lazy(() => import('./pages/Wallet/Wallet'))
const MyWallet = React.lazy(() => import('./pages/Wallet/MyWallet'))
const CreateAccount = React.lazy(() => import('./pages/Account/Create/Create'))
const CreateAvatar = React.lazy(() => import('./pages/Account/Create/CreateAvatar'))
const Create = React.lazy(() => import('./pages/Account/Create/index'))
const ConfirmAccount = React.lazy(() => import('./pages/Account/Create/ConfirmAccount'))
const Login = React.lazy(() => import('./pages/Account/Login'))
const Exchange = React.lazy(() => import('./pages/Exchange/index'))
const Portfolio = React.lazy(() => import('./pages/Portfolio/Portfolio'))
const Transaction = React.lazy(() => import('./pages/Transaction/Transaction'))
const LatestBlocks = React.lazy(() => import('./pages/LatestBlocks/LatestBlocks'))
const ImportSeed = React.lazy(() => import('./pages/ImportSeed/ImportSeed'))
const ImportFromSeedOrKey = React.lazy(() => import('./pages/ImportSeed/ImportFromSeedOrKey'))
const ImportAccountsFromKeystoreFiles = React.lazy(() => import('./pages/ImportSeed/ImportAccountsFromKeystoreFiles'))
const DoNotHaveKrosscoin = React.lazy(() => import('./pages/ImportSeed/DoNotHaveKrosscoin'))
const Protection = React.lazy(() => import('./pages/Protection/Protection'))
const SeedPhrase = React.lazy(() => import('./pages/SeedPhrase/SeedPhrase'))
const Confirmed = React.lazy(() => import('./pages/SeedPhrase/Confirmed'))
const Leasing = React.lazy(() => import('./pages/Leasing/Leasing'))

function App() {
	const { isAuthenticated } = useContext(AuthContext);
	return (
		<Routes>
			<Route path="/" element={
				<Suspense fallback={<SuspenseLoader />}>
					<Home2 />
				</Suspense>
			} />
			<Route path="/Home" element={
				<Suspense fallback={<SuspenseLoader />}>
					<Home />
				</Suspense>
			} />
			<Route path="/products">
				<Route index={true} element={
					<Suspense fallback={<SuspenseLoader />}>
						<Products />
					</Suspense>
				} />
				<Route path='services' element={
					<Suspense fallback={<SuspenseLoader />}>
						<Services />
					</Suspense>
				} />
			</Route>
			<Route index={true} path='/wallet' element={
				<Suspense fallback={<SuspenseLoader />}>
					<Wallet />
				</Suspense>
			} />

			<Route index={true} path='/mywallet' element={
				<Suspense fallback={<SuspenseLoader />}>
					<PrivateRoute isAuthenticated={isAuthenticated} component={MyWallet} />
				</Suspense>
			} />

			<Route index={true} path='/login' element={
				<Suspense fallback={<SuspenseLoader />}>
					<AuthRoute isAuthenticated={isAuthenticated} component={Login} />
				</Suspense>
			} />
			<Route path='latest-blocks' element={
				<Suspense fallback={<SuspenseLoader />}>
					<PrivateRoute isAuthenticated={isAuthenticated} component={LatestBlocks} />
				</Suspense>
			} />
			<Route index={true} path='/CreateAccount' element={
				<Suspense fallback={<SuspenseLoader />}>
					<CreateAccount />
				</Suspense>
			} />
			<Route index={true} path='/CreateAccount/Create' element={
				<Suspense fallback={<SuspenseLoader />}>
					<CreateRoute isAuthenticated={isAuthenticated} component={Create} />
				</Suspense>
			} />
			<Route index={true} path='/CreateAccount/CreateAvatar' element={
				<Suspense fallback={<SuspenseLoader />}>
					<CreateRoute isAuthenticated={isAuthenticated} component={CreateAvatar} />
				</Suspense>
			} />
			<Route index={true} path='/CreateAccount/ConfirmAccount' element={
				<Suspense fallback={<SuspenseLoader />}>
					<CreateRoute isAuthenticated={isAuthenticated} component={ConfirmAccount} />
				</Suspense>
			} />
			<Route index={true} path='/Exchange' element={
				<Suspense fallback={<SuspenseLoader />}>
					<PrivateRoute isAuthenticated={isAuthenticated} component={Exchange} />
				</Suspense>
			} />
			<Route index={true} path='/Portfolio' element={
				<Suspense fallback={<SuspenseLoader />}>
					<PrivateRoute isAuthenticated={isAuthenticated} component={Portfolio} />
				</Suspense>
			} />
			<Route index={true} path='/Transaction' element={
				<Suspense fallback={<SuspenseLoader />}>
					<PrivateRoute isAuthenticated={isAuthenticated} component={Transaction} />
				</Suspense>
			} />
			<Route index={true} path='/seed' element={
				<Suspense fallback={<SuspenseLoader />}>
					<CreateRoute isAuthenticated={isAuthenticated} component={ImportSeed} />
				</Suspense>
			} />
			<Route index={true} path='/import' element={
				<Suspense fallback={<SuspenseLoader />}>
					<CreateRoute isAuthenticated={isAuthenticated} component={ImportFromSeedOrKey} />
				</Suspense>
			} />
			<Route index={true} path='/importaccount' element={
				<Suspense fallback={<SuspenseLoader />}>
					<CreateRoute isAuthenticated={isAuthenticated} component={ImportAccountsFromKeystoreFiles} />
				</Suspense>
			} />
			<Route index={true} path='/installac' element={
				<Suspense fallback={<SuspenseLoader />}>
					<CreateRoute isAuthenticated={isAuthenticated} component={DoNotHaveKrosscoin} />
				</Suspense>
			} />
			<Route index={true} path='/protection' element={
				<Suspense fallback={<SuspenseLoader />}>
					<PrivateRoute isAuthenticated={isAuthenticated} component={Protection} />
				</Suspense>
			} />
			<Route index={true} path='/seedphrase' element={
				<Suspense fallback={<SuspenseLoader />}>
					<PrivateRoute isAuthenticated={isAuthenticated} component={SeedPhrase} />
				</Suspense>
			} />
			<Route index={true} path='/confirmed' element={
				<Suspense fallback={<SuspenseLoader />}>
					<PrivateRoute isAuthenticated={isAuthenticated} component={Confirmed} />
				</Suspense>
			} />
			<Route index={true} path='/leasing' element={
				<Suspense fallback={<SuspenseLoader />}>
					<PrivateRoute isAuthenticated={isAuthenticated} component={Leasing} />
				</Suspense>
			} />

			<Route path='*' element={<Navigate to="/" />} />
		</Routes>
	);
}

export default App;
