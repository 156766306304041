import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

const CreateRoute = ({ component: Component, isAuthenticated }) => {
  return isAuthenticated && localStorage.getItem('step') ? (
    <Component />
  ) : (localStorage.getItem('step').length !== 0 ?
    <Navigate to="/login" /> :
    <Navigate to="/createaccount" />
  );
};

export default CreateRoute;
